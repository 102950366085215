@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&family=Share+Tech+Mono&display=swap");
:root {
  --black-1: #000000;
  --black-2: rgba(0, 0, 0, .7);
  --blue-1: #2b3a67;
  --blue-2: #496a81;
  --cyan: #7a9f98;
  --grey: #c9b1bd;
  --pink-1: #e5c2c0;
  --pink-2: #d8829d;
  --orange-1: #edb458;
  --orange-2: #e8871e;
  --orange-3: #ffc482;
  --white: #ffffff;
  --yellow: #edd83d;
  --text-font: "Share Tech Mono";
  --background-color: var(--black-1);
  --border-color: var(--pink-2);
  --text-color: var(--white);
  --box-background-color: var(--black-2);
}

* {
  box-sizing: border-box;
}

body {
  background: var(--background-color);
  box-sizing: border-box;
  font-family: var(--text-font);
  overflow: hidden;
}

p {
  margin: 0;
}

button {
  display: block;
  width: 100%;
  background: var(--yellow);
  color: var(--black-1);
  border: solid 1px var(--blue-2);
  box-shadow: 0.2rem 0.2rem 0.2rem var(--blue-2);
  font-family: var(--text-font);
  font-size: 1rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.close-window {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: block;
  width: initial;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  color: var(--orange-2);
}

button.link {
  border: none;
  background: transparent;
  box-shadow: none;
  color: var(--orange-2);
  text-decoration: underline;
  width: initial;
}

.story-line {
  margin: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
.story-line.style-input {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.story-line.style-input form {
  border: solid 1px var(--cyan);
  padding: 1rem;
  background: var(--white);
  box-shadow: 0.5rem 0.5rem 0.5rem var(--black-1);
}
.story-line.style-input .text-input {
  font-size: 1rem;
  display: block;
  width: 100%;
  padding: 0.2rem 0.5rem;
  text-align: center;
  margin: 0.5rem 0;
  font-family: "Shadows Into Light";
  color: var(--yellow);
  background: var(--black-2);
}
.story-line.style-narrative {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: Garamond, serif;
  margin: 0;
  background: var(--black-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.story-line.style-narrative .speaker-name {
  display: none;
}
.story-line.style-narrative .text {
  border: none;
  background: transparent;
}
.story-line.style-narrative form {
  width: 100%;
  padding: 0 1rem;
}
.story-line.style-narrative .text-input {
  font-size: 1rem;
  display: block;
  width: 100%;
  padding: 0.2rem 0.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
  font-family: "Shadows Into Light";
  color: var(--yellow);
  background: var(--black-2);
}
.story-line.style-narrative .input-submit-button {
  width: 100%;
  display: block;
}
.story-line.style-narrative.has-options {
  background-color: transparent;
  text-shadow: 0.1em 0.1em 0.1em var(--black-1);
}
.story-line .speaker-name {
  background: #e88710;
  color: #000000;
  display: inline-block;
  margin-left: 1rem;
  padding: 0.2rem 0.5rem;
  border: solid 0.125rem var(--border-color);
  border-bottom: none;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.story-line .text {
  color: var(--text-color);
  background: var(--box-background-color);
  border: solid 0.125rem var(--border-color);
  border-radius: 0.5rem;
  padding: 1rem;
  min-height: 4rem;
}

.portrait {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  animation-name: slide-in;
  animation-duration: 0.5s;
}
.portrait img {
  image-rendering: pixelated;
  display: block;
  height: calc(100vh - 2rem);
  width: 100vw;
  object-fit: contain;
  object-position: right bottom;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #000000) drop-shadow(0.2rem -0.2rem 0.2rem #000000) drop-shadow(-0.2rem 0.2rem 0.2rem #000000) drop-shadow(-0.2rem -0.2rem 0.2rem #000000);
}

@keyframes slide-in {
  0% {
    opacity: 0.5;
    transform: translateX(5rem);
  }
  100% {
    opacity: 1;
  }
}
.progress-button {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: transparent;
  border: none;
  font-size: 0;
  margin: 0;
  box-shadow: none;
}

.choices-wrapper {
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.choices {
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 1rem;
  text-align: center;
}
.choices button.selected {
  background: var(--pink-2);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.start-menu {
  position: absolute;
  top: 2.5rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  background: var(--black-2);
  color: var(--white);
  padding: 0.5rem;
  border: solid 2px var(--cyan);
  z-index: 25;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--black-2);
  color: var(--pink-2);
  padding: 1rem;
  text-align: center;
}
.start-menu h1 {
  text-shadow: 0.25rem 0.25rem 0.25rem var(--black-1);
}

.story-log {
  position: absolute;
  top: 2.5rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  background: var(--black-2);
  color: var(--white);
  padding: 0.5rem;
  border: solid 2px var(--cyan);
  z-index: 25;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
}
.story-log .log-line {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}
.story-log .log-speaker-name {
  font-weight: bold;
  color: var(--orange-1);
  padding-right: 0.5rem;
}
.story-log .log-style-narrative {
  font-style: italic;
}
.story-log .log-style-dialog .log-line-text::before, .story-log .log-style-dialog .log-line-text::after {
  content: '"';
}
.story-log .log-style-poem {
  font-family: "Shadows Into Light";
}
.story-log .log-tooltip {
  text-align: center;
  font-style: italic;
  border-top: solid 1px var(--yellow);
  margin-top: 1rem;
  padding-top: 0.25rem;
}
.story-log .log-tooltip .tast {
  font-style: normal;
  font-weight: bold;
  color: var(--pink-2);
}

.save-window {
  position: absolute;
  top: 2.5rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  background: var(--black-2);
  color: var(--white);
  padding: 0.5rem;
  border: solid 2px var(--cyan);
  z-index: 25;
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  gap: 0.5rem;
}

.save-slot {
  border: solid 1px var(--pink-1);
  background: var(--black-1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  text-shadow: 0.1em 0.1em 0.1em var(--black-1);
}
.save-slot h2 {
  font-size: 1em;
}
.save-slot button {
  display: block;
  font-size: 1em;
  padding: 0.2rem;
  margin: 1em 0.5em 0;
}

.load-save-buttons {
  display: flex;
}

.options {
  position: absolute;
  top: 2.5rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  background: var(--black-2);
  color: var(--white);
  padding: 0.5rem;
  border: solid 2px var(--cyan);
  z-index: 25;
}
.options > :first-child {
  margin-top: 2rem;
}

.delete-autosave {
  margin-top: 2rem;
}

.single-setting {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
}
.single-setting p {
  width: 100%;
  margin-bottom: 1rem;
}
.single-setting input {
  display: block;
  width: 100%;
}

.credits {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  background: #000000;
  text-align: center;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.credits section {
  animation-name: scroll-in-and-out;
  animation-duration: 5s;
}
.credits .thanks-for-playing {
  animation-name: scroll-in;
  animation-duration: 1s;
}
.credits h2 {
  color: var(--yellow);
  font-size: 1rem;
  margin: 2rem 0 0.5rem;
}
.credits h3 {
  color: var(--pink-1);
  font-size: 1rem;
  margin: 1rem 0 0.5rem;
}
.credits p {
  margin: 0 0 0.5rem;
}
.credits a {
  color: var(--cyan);
}
.credits .credit-columns {
  list-style-type: none;
}
.credits .developed-by {
  font-size: 0.8rem;
  font-family: "Shadows Into Light";
  color: var(--pink-2);
}
.credits .next-credits-page-button {
  display: block;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  border: none;
  width: initial;
  color: var(--white);
  background: transparent;
  box-shadow: none;
}

@keyframes scroll-in-and-out {
  0% {
    transform: translateY(100vh);
  }
  20% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
@keyframes scroll-in {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
.top-menu {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  height: 2rem;
  color: var(--white);
  background: var(--black-2);
  display: flex;
  border-bottom: solid 1px var(--cyan);
}
.top-menu button {
  display: block;
  font-size: 1rem;
  color: var(--white);
  background: transparent;
  box-shadow: none;
  border: none;
  padding: 0.5rem;
  margin-bottom: 0;
}

.game-over {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: var(--black-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.minigame {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  padding: 1rem;
  overflow: auto;
  text-align: center;
  animation-name: enter-screen;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  background: var(--white);
  /*	font-family: Garamond, serif;*/
}
.minigame h1 {
  font-size: 1.5rem;
}
.minigame h2 {
  font-size: 1.25rem;
}
.minigame p, .minigame ol li {
  margin-bottom: 0.8rem;
}

@keyframes enter-screen {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@media screen and (min-width: 800px) {
  html {
    font-size: 200%;
  }

  .choices {
    position: static;
    min-width: 50%;
    columns: 2;
    column-gap: 0.5rem;
  }
  .choices[data-choices="1"], .choices[data-choices="2"], .choices[data-choices="3"], .choices[data-choices="4"], .choices[data-choices="5"] {
    columns: 1;
  }

  .story-log {
    font-size: 0.5rem;
  }
  .story-log .log-line {
    flex-direction: row;
  }
  .story-log .log-speaker-name {
    min-width: 20%;
    text-align: right;
  }

  .single-setting {
    flex-direction: row;
    text-align: left;
  }
  .single-setting p {
    width: 50%;
    flex-grow: 1;
  }

  .delete-autosave {
    font-size: 0.5rem;
  }
  .delete-autosave button {
    font-size: 0.5rem;
  }

  .credits p {
    font-size: 0.75rem;
  }

  .save-window {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .save-window .save-slot {
    font-size: 0.5rem;
  }
}
/* NNT */
.puzzle {
  --number-of-letters: 10;
}
.puzzle ol {
  text-align: left;
  margin-left: 2rem;
}
.puzzle .kryssord {
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #000000);
  max-width: 100%;
}
.puzzle .kryssordclue {
  max-width: 100%;
}

.puzzle[data-answer-length="3"] {
  --number-of-letters: 3;
}

.puzzle[data-answer-length="4"] {
  --number-of-letters: 4;
}

.puzzle[data-answer-length="5"] {
  --number-of-letters: 5;
}

.puzzle[data-answer-length="6"] {
  --number-of-letters: 6;
}

.puzzle[data-answer-length="7"] {
  --number-of-letters: 7;
}

.puzzle[data-answer-length="8"] {
  --number-of-letters: 8;
}

.puzzle[data-answer-length="9"] {
  --number-of-letters: 9;
}

.puzzle[data-answer-length="10"] {
  --number-of-letters: 10;
}

.puzzle[data-answer-length="11"] {
  --number-of-letters: 11;
}

.puzzle[data-answer-length="12"] {
  --number-of-letters: 12;
}

.puzzle[data-answer-length="13"] {
  --number-of-letters: 13;
}

.puzzle[data-answer-length="14"] {
  --number-of-letters: 14;
}

.puzzle[data-answer-length="15"] {
  --number-of-letters: 15;
}

.puzzle-answer-button {
  margin-top: 2rem;
}

.puzzle-answer-wrapper {
  background: var(--black-2);
  color: var(--white);
  position: fixed;
  display: flex;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.puzzle-answer-wrapper h2 {
  color: var(--yellow);
}

.puzzle-answer {
  position: relative;
  display: flex;
  --letter-box-width: calc((100vw - ((var(--number-of-letters) - 1) * .4rem) - 2rem) / var(--number-of-letters));
  margin-bottom: 1rem;
}

.puzzle-answer-letter {
  font-family: "Shadows Into Light";
  margin: 0.2rem;
  color: var(--black-1);
  background: var(--white);
  width: var(--letter-box-width);
  height: var(--letter-box-width);
  line-height: var(--letter-box-width);
  font-size: calc(var(--letter-box-width) * 0.6);
  font-weight: bold;
  border: solid 0.1rem var(--blue-1);
  box-shadow: 0.5rem 0.5rem 0.5rem var(--black-1);
}
.puzzle-answer-letter.active {
  animation-name: blinking-letter;
  animation-duration: 0.6s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.puzzle-answer-letter.space {
  background: var(--black-1);
  color: var(--white);
}

@keyframes blinking-letter {
  0% {
    background: var(--white);
  }
  40% {
    background: var(--white);
  }
  60% {
    background: var(--pink-2);
  }
  100% {
    background: var(--pink-2);
  }
}
.puzzle-answer-input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.wrong-answer {
  font-weight: bold;
  color: var(--pink-2);
}

.bottom-links {
  display: flex;
  justify-content: space-between;
}
.bottom-links a {
  display: block;
  margin: 0.5rem;
  color: var(--orange-2);
}

.story-line.style-poem {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: "Shadows into Light";
  font-style: italic;
  margin: 1rem 1rem 0;
  background: var(--grey);
  color: var(--black-1);
  border: solid 1px var(--black-1);
  border-bottom: none;
  box-shadow: 0.5em 0.5em 0.5em var(--black-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.story-line.style-poem .speaker-name {
  display: none;
}
.story-line.style-poem .text {
  color: var(--black-1);
  border: none;
  background: transparent;
  animation-duration: 0.2s;
}
.story-line.style-poem .text::first-letter {
  font-weight: bold;
  font-size: 1.1em;
}

.diktbok {
  font-family: Garamond;
}

.handskrift {
  font-family: "Shadows Into Light";
  font-weight: bold;
}

.linje {
  list-style-type: none;
  text-align: left;
  margin: 0 0 3rem;
  break-inside: avoid-column;
  border-left: solid 0.5rem #000000;
  padding: 0;
}
.linje li {
  padding: 0.1rem 0 0.1rem 0.25rem;
}

.linje1 {
  border-left-color: #009cdc;
}

.linje2 {
  border-left-color: #ea5b0b;
}

.linje3 {
  border-left-color: #a683a5;
}

.linje4 {
  border-left-color: #00489a;
}

.linje5 {
  border-left-color: #3aaa35;
}

@media screen and (min-width: 800px) {
  .linjer {
    columns: 2;
  }
}
.story-line.style-pcskjerm {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: var(--black-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.story-line.style-pcskjerm .speaker-name {
  display: none;
}
.story-line.style-pcskjerm .text {
  border: none;
  background: transparent;
  color: var(--orange-3);
}

.hintbilder {
  display: flex;
  flex-wrap: wrap;
}
.hintbilder img {
  display: block;
  height: 200px;
  margin: 0 0.5rem 0.5rem 0;
  border: solid 1px var(--black-1);
  box-shadow: 0.25rem 0.25rem 0.25rem var(--black-1);
}

